import React from 'react'
import { Link } from 'gatsby'
import Post from 'components/post';
import { Row, Col } from 'react-bootstrap';
import Layout from 'layouts';

export default (props) => (
  <Layout {...props}>
    <Row style={{margin: '0px 15px 30px 15px'}}>
      {props.pageContext.group.map(post => <Post post={post.node} button summary line meta />)}
      <Pagination {...props.pageContext} />
    </Row>
  </Layout>
)

const Pagination = (props) => {
  let pages = [];
  for (let i = 1; i<= props.pageCount; i++) {
    if (i == props.index)
      pages.push(<li><span className="page-numbers current">{i}</span></li>)
    else
      pages.push(<li><Link className="page-numbers" to={`/blog/${i == 1 ? '' : i}`}>{i}</Link></li>)
  }

  return (
    <ul className="page-numbers" style={{marginLeft: 'auto', marginRight: 'auto'}}>
      {props.index != 1 ?
        <li><Link className="next page-numbers" to={`/blog/${(props.index - 1) == 1 ? '' : props.index}`}>Prev</Link></li>
        : null}
      {pages}
      {props.pageCount != props.index ?
        <li><Link className="next page-numbers" to={`/blog/${props.index + 1}`}>Next</Link></li>
        : null}
    </ul>
  )
}
