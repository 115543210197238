import React from 'react';
import Moment from 'moment';
//import ReactDisqusComments from 'react-disqus-comments';
import {Link} from 'gatsby';
import {Row, Col} from 'react-bootstrap';

export default (props) => (
  <div style={{marginTop: '20px'}}>
    <Title {...props} />
    <Content {...props} />
  </div>
)

export const Title = (props) => (
  <Row>
    <Col md={{span: 8, offset: 2}}>
      <div style={{display: 'inherit', textAlign: 'center'}}>
        <Link to={`/${props.path || 'blog'}` + props.post.fields.slug} style={{textDecoration: 'none'}}>
          <h1 className="entry-title-pro blog-post-single-progression" style={{fontSize: '36px', marginTop: 0, marginBottom: '4px'}}>{props.post.frontmatter.title}</h1>
          <h4 className="entry-title-pro blog-post-single-progression" style={{color: 'gray', marginTop: 0}}>{props.post.frontmatter.subtitle}</h4>
        </Link>
        {props.spacer && <br />}
        {props.meta && <ul className="post-meta-pro" style={{fontSize: '18px', marginTop: '25px'}}>
            <li className="date-meta-pro">{Moment(props.post.frontmatter.date).format('MMM DD YYYY')}</li>
            <li className="author-meta-pro"><i className="fa fa-user"></i>{props.post.frontmatter.author}</li>
        </ul>}
  </div>
  </Col>
  </Row>
)

export const Content = (props) => (
  <Row>
    <Col md={{span: 6, offset: 3}}>
      <div className="page-content-pro" style={{fontSize: '18px', color: '#3b3b3b'}}>
        <article className="post">
          <div className="post-container-pro featured-blog-single" style={props.line ? {} : {border: 'none'}}>
            <div className="summary-post-pro">
              {props.summary ? props.post.excerpt : <div dangerouslySetInnerHTML={{__html: props.post.html}}></div>}
            </div>
            {props.button && <Button {...props} />}
            {/*props.comments && <Comments {...props} />*/}
          </div>
        </article>
      </div>
    </Col>
  </Row>
)

export const Button = (props) => (
  <Row>
    <div className="progression-row pro-row-button" style={{backgroundColor: '#fff', paddingTop: 0, paddingTop: '40px', paddingBottom: '20px', marginLeft: 'auto', marginRight: 'auto'}}>
      <div className="progression-btn-container">
        <Link className="pro-btn-invested icon-btn-pro" to={`/${props.path || 'blog'}` + props.post.fields.slug} style={{paddingLeft: '25px', paddingRight: '25px', textDecoration: 'none'}}>Continue reading<i className="fa fa-angle-right"></i></Link>
      </div>
    </div>
  </Row>
)

{/*const Comments = (props) => (
  <ReactDisqusComments
    shortname="valuestream-ventures"
    identifier={props.post.fields.slug}
    title={props.post.frontmatter.title}
    url={`https://www.valuestreamventures.com/blog/${props.post.fields.slug}`}
  />
)*/}
